import React, { useState, useEffect, createRef } from 'react'

import { Container, Row, Col } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
//api
import {
  getListById,
  getAllLists,
  saveAutomaticList,
  editOrDeleteList,
  getProductsBack,
  getAllListElements,
} from '../../../api/pathsForHook/products'
import { getFilteredCollectionsListBack } from '../../../api/pathsForHook/collections'
// components
import InputCustom from '../../../components/input'
import { ComboButtons } from '../../../components/buttons'
import Select from '../../../components/select'
import DragDropComponent from '../../../components/dragDrop'
// router
import { useParams, useNavigate } from 'react-router-dom'
// helpers
import { showToast, typeProductList, typeCoverList, checkForErrors } from '../../../helpers'
import { useTranslation } from 'react-i18next'
// own hooks
import useFetch from '../../../hooks/useFetch'
import uuid from 'uuid/v4'
import 'react-bootstrap-typeahead/css/Typeahead.css'

const ListDetails = () => {
  let { id } = useParams()
  const ref = createRef()
  const [names, setNames] = useState({
    name_es: '',
    name_en: '',
    name_eu: '',
    name_gl: '',
    name_ca: '',
    name_va: '',
  })
  const [productInfo, setProductInfo] = useState({})
  const [selectedProducts, setSelectedProducts] = useState([])
  const [products, setProducts] = useState([])
  const [columns, setColumns] = useState([])

  let navigate = useNavigate()

  const { t } = useTranslation('management')

  const { loading, methodGet, methodPost, methodPatch } = useFetch()

  useEffect(() => {
    if (id) {
      getListDetails(id)
    } else {
      setProductInfo({ content: 1, cover: 0 })
    }
  }, [])

  const getListDetails = async (id) => {
    const response = await methodGet(getListById(id))
    if (response) {
      const elements = await methodGet(getAllListElements(id))
      const { data } = response
      setNames({
        name_es: data.name_es,
        name_en: data.name_en,
        name_eu: data.name_eu,
        name_gl: data.name_gl,
        name_ca: data.name_ca,
        name_va: data.name_va,
      })
      setProductInfo({
        content: parseInt(data.content_id),
        cover: data.type_cover,
      })
      let dataList = []
      elements?.data.forEach((item) => {
        if (item.products) {
          dataList.push(item.products)
        }
        if (item.collections) {
          dataList.push(item.collections)
        }
      })
      setSelectedProducts(dataList)
    }
  }

  useEffect(() => {
    const columnsFromBackend = {
      [uuid()]: {
        items: selectedProducts,
      },
    }
    setColumns(columnsFromBackend)
  }, [selectedProducts])

  const onDragEnd = (result, columns) => {
    if (!result.destination) return
    const { source, destination } = result

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId]
      const destColumn = columns[destination.droppableId]
      const sourceItems = [...sourceColumn.items]
      const destItems = [...destColumn.items]
      const [removed] = sourceItems.splice(source.index, 1)
      destItems.splice(destination.index, 0, removed)
      let newValueOfColumns = {
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      }
      setColumns(newValueOfColumns)
      setSelectedProducts(Object.values(newValueOfColumns)[0].items)
    } else {
      const column = columns[source.droppableId]
      const copiedItems = [...column.items]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      })
      setSelectedProducts(copiedItems)
    }
  }

  const filterBy = () => true
  const handleSearch = async (query) => {
    if (query.length >= 3){
      let querySearch = { 
        name: query, 
        skip_chapters: true, 
        fields: "id,name,provider,type", 
        page_size: 50
      }
      if (productInfo.content === 2 || productInfo.content === 4) {
        if (productInfo.content === 2) {
          querySearch.product_type = 'audiovisual'
        }
        if (productInfo.content === 4) {
          querySearch.product_type = 'music'
        }
        const { status, data } = await methodGet(getProductsBack(1), querySearch)
        if (status === 200 || status === 201) {
          setProducts(data.results)
        }
      } else if (productInfo.content === 5 || productInfo.content === 3 || productInfo.content === 6) {
        if (productInfo.content === 3) {
          querySearch.type = 3
        }
        if (productInfo.content === 6) {
          querySearch.type = 5
        }
        const { status, data } = await methodGet(getFilteredCollectionsListBack(1), querySearch)
        if (status === 200 || status === 201) {
          setProducts(data.results)
        }
      } else {
        const productData = await methodGet(getProductsBack(1), querySearch)
        const seriesData = await methodGet(getFilteredCollectionsListBack(1), querySearch)
        const array = productData?.data?.results.concat(seriesData?.data.results)
        setProducts(array)
      }
    }
  }

  const hideSelectedProduct = async (selected) => {
    const boxes = document.getElementsByClassName('rbt-token-removeable')
    boxes && boxes.length > 0 && selected.map((item, i) => (boxes[i].style.display = 'none'))
  }

  const handleSelectedProducts = (values) => {
    if (values !== null) {
      hideSelectedProduct(values)
      values.map((item, i) => {
        item.id = item.id.toString()
      })
      const newValue =  values[values.length - 1]
      const noDuplicatesList = [...selectedProducts]
      const duplicateExists = noDuplicatesList.some((s) => s.id.toString() === newValue.id.toString())
      if (!duplicateExists) {
        noDuplicatesList.unshift(newValue)
      } else {
        showToast('error', t('listManagementContent.duplicatedValueError'))
      }
      setSelectedProducts(noDuplicatesList)
    } else {
      setSelectedProducts([])
    }
  }

  const saveList = async () => {
    if (checkForErrors(names.name_es, '')) {
      showToast('error', t('listManagementContent.errorName'))
    } else {
      let dataElements = []
      Object.entries(columns).map(([columnId, column], index) => {
        column.items.map(({ id, type }, ind) => {
          dataElements.push({
            element: parseInt(id),
            type: parseInt(productInfo.content),
            order: ind + 1,
            section: type,
          })
        })
      })
      const payload = {
        type: 2,
        name: names.name_es,
        name_es: names.name_es,
        name_en: names.name_en,
        name_eu: names.name_eu,
        name_gl: names.name_gl,
        name_ca: names.name_ca,
        name_va: names.name_va,
        category: productInfo.category,
        type_cover: productInfo.cover,
        elements: dataElements,
        content: productInfo.content,
      }
      let response = {}
      if (!id) {
        response = await methodPost(getAllLists(1), payload)
      } else {
        response = await methodPatch(editOrDeleteList(id), payload)
      }
      if (response) {
        showToast('success', t('listManagementContent.success'))
        if (!id) {
          const { status } = await methodGet(saveAutomaticList(response.data.id))
          console.log('status after assigning list to all clients', status)
        }
        navigate('/administrator/content-management/lists/', {
          state: 'detail',
        })
      } else {
        showToast('error', t('listManagementContent.error'))
      }
    }
  }

  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <h2>{id ? t('listManagementContent.edit') : t('listManagementContent.create')}</h2>
      <Row className="my-2 w-100 d-flex justify-content-between">
        <Col lg={5} md={6} sm={8} xs={12}>
          {Object.keys(names).map((item, i) => (
            <Col lg={12} className="input-column" key={i}>
              <InputCustom
                key={i}
                label={item}
                placeholder={t(`listManagementContent.${item}`)}
                className="border border-gray "
                value={names[item]}
                onChange={(e) => {
                  setNames({ ...names, [item]: e.target.value })
                }}
              />
            </Col>
          ))}
        </Col>

        <Col lg={5} md={6} sm={8} xs={12}>
          {productInfo.content && (
            <>
              <div className="input-column">
                <Select
                  label={t('listManagementContent.chooseProductType')}
                  options={typeProductList}
                  defaultValue={productInfo.content}
                  handleSelect={(value) => {
                    setProductInfo({ ...productInfo, ['content']: parseInt(value) })
                  }}
                />
              </div>
              <div className="input-column">
                <Select
                  label={t('listManagementContent.chooseCoverType')}
                  options={typeCoverList}
                  defaultValue={productInfo.cover}
                  handleSelect={(value) => {
                    setProductInfo({ ...productInfo, ['cover']: value })
                  }}
                />
              </div>
            </>
          )}
          <div
            style={{
              marginTop: '2rem',
            }}
          >
            <label className="txt-choose">{t('listManagementContent.chooseProducts')}</label>
            <Col>
              <AsyncTypeahead
                filterBy={filterBy}
                multiple
                id="id_search_products"
                isLoading={loading}
                labelKey={'name'}
                minLength={3}
                onSearch={(value) => handleSearch(value)}
                onInputChange={(input, e) => handleSearch(input)}
                onChange={(values) => handleSelectedProducts(values)}
                options={products}
                placeholder={t('searchByTitle')}
                /*  disabled={checkDisableSearch(isAutomatic, selectedList)} */
                ref={ref}
              />
              {selectedProducts?.length > 0 && (
                <DragDropComponent
                  columns={columns}
                  onDragEnd={onDragEnd}
                  selectedProducts={selectedProducts}
                  sendSelectedProducts={setSelectedProducts}
                />
              )}{' '}
            </Col>
          </div>
        </Col>

        <Col lg={5} md={2} xs={12} className="text-md-end"></Col>
      </Row>
      <Row>
        <Col>
          <ComboButtons
            cancelRoute={'/administrator/content-management/lists'}
            primaryText={!id ? t('create', { ns: 'groupList' }) : t('save', { ns: 'groupList' })}
            secondaryText={t('cancel', { ns: 'groupList' })}
            handleClick={saveList}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default ListDetails
