import { DateTime } from 'luxon'
import { toast } from 'react-toastify'
import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css'

import { API_EFILM } from './constants'

/**
 * @param {string} key - the key property to check out in the localstorage
 * @returns either the JSON content from key param or null if content there is no exist.
 */
const getValueStorage = (key) => localStorage.getItem(key)

/**
 * @param {string} key - the key property to store in the localstorage
 * @param {any} value - the value from the key to store in the localstorage
 * @returns Null
 */
const setValueStorage = (key, value) => localStorage.setItem(key, value)

const getDateFormat = (dateStr, format = 'dd LLL yyyy') => {
  return DateTime.fromISO(dateStr).toFormat(format)
}

export function showToast(type, message, duration) {
  const settings = {
    position: `top-right`,
    autoClose: duration ? duration : 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }
  if (type === undefined || type === `default`) {
    toast(message, settings)
  } else {
    toast[type](message, settings)
  }
}

const downloadFile = async (endpoint, query, token, name, setEnabled, setCodeError = null, type_doc = 'xls') => {
  axios({
    url: API_EFILM + endpoint,
    method: 'GET',
    params: query,
    responseType: 'blob', // important
    headers: {
      Authorization: 'JWT ' + token,
    },
  })
    .then((response) => {
      console.log('then response', response)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${name}_${new Date().toISOString()}.${type_doc}`)
      document.body.appendChild(link)
      link.click()
      setEnabled(true)
    })
    .catch((error) => {
      console.log(error)
      const { status } = error.response
      if (setCodeError) setCodeError(status)

      console.log('Error exporting Excel ->', status)
      setEnabled(true)
    })
}

export { getValueStorage, setValueStorage, getDateFormat, downloadFile }

export const categoriesHomePages = [
  { id: 'N', name: 'None' },
  { id: 'H', name: 'Main home' },
  { id: 'HPA', name: 'Performing arts' },
  { id: 'HK', name: 'Kids' },
  { id: 'HM', name: 'Movies' },
  { id: 'HG', name: 'Games' },
  { id: 'HS', name: 'Series' },
  { id: 'HMG', name: 'Magazines' },
  { id: 'AK', name: 'Audiovisual Kids' },
  { id: 'HP', name: 'Provider home' },
  { id: 'HTS', name: 'TV Shows' },
  { id: 'HMN', name: 'Manga'},
  { id: 'HPO', name: 'Podcasts'},
]

export const defaultHomePages = [
  { id: 'N', name: 'None' },
  { id: 'H', name: 'Main home' },
  { id: 'HP', name: 'Provider home' },
]

export const typeProductList = [
  { id: 1, name: 'All' },
  { id: 2, name: 'Audiovisuals' },
  { id: 3, name: 'Magazines' },
  { id: 4, name: 'Music' },
  { id: 5, name: 'Collections' },
  { id: 6, name: 'Podcasts'}
]

export const typeCoverList = [
  { id: 0, name: 'Multiple' },
  { id: 1, name: 'Vertical' },
  { id: 2, name: 'Horizontal' },
]

export const checkForErrors = (data, initialState) => {
  if (data && data !== initialState) {
    return false
  } else return true
}
