// ========== GET METHODS ==========

export const getCollections = (page) =>
  page === 1
    ? "products/collections-administrator/"
    : `products/collections-administrator/?page=${page}`;

export const getFilteredCollectionsListBack = (page) =>
  page == 1
    ? "products/collections-back/"
    : `products/collections-back/?page=${page}`;


// ========== PATCH METHODS ==========

export const changeCollections = (id) =>
  `products/collections-administrator/${id}/`;
