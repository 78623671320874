import React, { useState, useEffect } from 'react'
//api
import {
  addListToGroupList,
  createGroup,
  deleteListFromGroupList,
  editListFromGroupList,
  changeListCategory,
  getAllLists,
  getGroupDetailsById,
  updateGroup,
} from '../../../api/pathsForHook/products'
// components
import { Container, Row, Col, Breadcrumb, Spinner } from 'react-bootstrap'
import { Table } from 'reactstrap'
import InputCustom from '../../../components/input'
import { ComboButtons, CustomButton } from '../../../components/buttons'
import Loading from '../../../components/Loading'
import ButtonGoBack from '../../../components/buttons/GoBackButton'
import ModalConstructor from '../../../components/modals/ModalConstructor'
import Select from '../../../components/select'
// router
import { useParams, useNavigate } from 'react-router-dom'
//icons
import { RiDeleteBinLine, RiAddFill } from 'react-icons/ri'
import { FaGripVertical } from 'react-icons/fa'
// helpers
import { showToast, categoriesHomePages } from '../../../helpers'
import { useTranslation } from 'react-i18next'
// css
import '../../../assets/css/group_lists.scss'
// own hooks
import useFetch from '../../../hooks/useFetch'
// dnd
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const GroupDetails = () => {
  let { id } = useParams()

  const [groupDetails, setGroupDetails] = useState(null)
  const [listsByNameIsLoading, setListsByNameIsLoading] = useState(false)
  const [isChangingStateOfList, setIsChangingStateOfList] = useState(false)
  const [isStatus, setIsStatus] = useState(null)
  const [inputNameValue, setInputNameValue] = useState('')
  const [inputSearchListsValue, setInputSearchListsValue] = useState('')
  const [listsByName, setListsByName] = useState([])
  const [selectedLists, setSelectedLists] = useState([])

  // separation of lists by category
  const [sortedListsByCategoryCode, setSortedListsByCategoryCode] = useState(null)

  // modal edit list
  const [modalOpen, setModalOpen] = useState(false)
  const [listToEdit, setListToEdit] = useState(null)
  const [modalEditListElements, setModalEditListElements] = useState(null)

  useEffect(() => {
    listToEdit !== null &&
      setModalEditListElements([
        { section: 'header', type: 'title', text: listToEdit.list_name },
        {
          section: 'body',
          type: 'input',
          label: 'Nombre de la lista',
          inputType: 'text',
          value: listToEdit.list_name,
          defaultValue: listToEdit.list_name,
          keyName: 'list_name',
        },
        {
          section: 'footer',
          type: 'button-close',
          text: 'Cancel',
        },
        {
          section: 'footer',
          type: 'button-submit',
          text: 'Save',
        },
      ])
  }, [listToEdit])

  const toggleModal = () => setModalOpen(!modalOpen)

  let navigate = useNavigate()
  const { t } = useTranslation('groupList')

  const { methodGet, methodPost, methodDelete, methodPut, methodPatch, loading } = useFetch()

  const getGroupDetails = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await methodGet(getGroupDetailsById(id))
        if (data) {
          const list_data_sorted_by_order_value = data.list_data.sort((a, b) => a.order - b.order)
          setGroupDetails(data)
          setInputNameValue(data.name)
          setSelectedLists(list_data_sorted_by_order_value)
          setIsStatus(200)
        }
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  const createNewGroup = async () => {
    const payload = {
      name: inputNameValue,
    }
    const response = await methodPost(createGroup(), payload)
    if (response) {
      showToast('success', t('groupCreatedSuccessful'))
      navigate(`/administrator/group-details/${response.data.id}`)
    }
  }

  const saveChanges = async () => {
    const payload = {
      ...groupDetails,
      name: inputNameValue,
    }

    const response = await methodPatch(updateGroup(id), payload)
    if (response) {
      showToast('success', t('changesSaved'))
      navigate('/administrator/group-lists')
    }
  }

  const selectNewList = (list) => {
    let listAlreadyAdded = selectedLists.some((el) => el.list === list.id)
    if (listAlreadyAdded) {
      showToast('error', t('listIsAlreadySelected'))
    } else {
      addListToGroup(id, list)
    }
  }

  const deleteSelectedList = async (id) => {
    if (isChangingStateOfList) {
      return
    }

    setIsChangingStateOfList(true)

    try {
      const response = await methodDelete(deleteListFromGroupList(id))
      response && setSelectedLists(selectedLists.filter((el) => el.id !== id))
      showToast('success', t('deletedSuccessful'))
    } catch (error) {
      console.log('Error', error)
      showToast('error', 'Error on delete')
    } finally {
      setIsChangingStateOfList(false)
    }
  }

  const getListsByName = async (page) => {
    try {
      const { data } = await methodGet(getAllLists(page), {
        search: inputSearchListsValue,
      })
      setListsByName(data.results)
    } catch (error) {
      showToast('info', t('serverError'))
    } finally {
      setListsByNameIsLoading(false)
    }
  }

  const addListToGroup = async (group_id, list) => {
    if (isChangingStateOfList) {
      return
    }

    setIsChangingStateOfList(true)

    const payload = {
      list_group: group_id,
      list: list.id,
      order: selectedLists.length,
      visible: true,
    }

    try {
      const { data } = await methodPost(addListToGroupList(), payload)

      showToast('success', t('listSelectedSuccessful'))

      setSelectedLists([
        ...selectedLists,
        {
          id: data.id,
          list: data.list,
          list_name: data.list_name,
          order: data.order,
          list_category: {
            code: data.list_category.code,
          },
          visible: true,
        },
      ])
    } catch (error) {
      showToast('error', t('errorOccurred'))
    }

    setIsChangingStateOfList(false)
  }

  const editList = async (list_id, payload) => {
    await methodPut(editListFromGroupList(list_id), payload, {
      skipLoading: true,
    })
  }
  const changeStateVisibilityOfList = async (list) => {
    if (!isChangingStateOfList) {
      setIsChangingStateOfList(true)

      let payload = {
        list_group: id,
        visible: !list.visible,
        list: list.list,
      }

      try {
        await editList(list.id, payload)
      } finally {
        getGroupDetails(id).then(() => {
          setIsChangingStateOfList(false)
        })
      }
    }
  }
  const reOrder = async (list, startIndex, endIndex) => {
    setIsChangingStateOfList(true)
    /*  console.log(list, startIndex, endIndex) */

    const result = [...list]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    /*  console.log('1', result) */

    const updatedList = result.map((list, index) => ({
      ...list,
      order: index + 1,
    }))

    /*  console.log('2', updatedList) */

    setSelectedLists(updatedList)
    await new Promise(async (resolve, reject) => {
      const promises = updatedList.map(async (list) => {
        const payload = {
          list_group: id,
          list: list.list,
          order: list.order,
        }
        await editList(list.id, payload)
      })

      await Promise.all(promises)
      resolve()
    })

    await getGroupDetails(id).then(() => {
      setIsChangingStateOfList(false)
    })
  }

  useEffect(() => {
    if (id && id !== 'new') {
      getGroupDetails(id).then(() => {
        setIsChangingStateOfList(false)
      })
    }
  }, [id])

  useEffect(() => {
    if (inputSearchListsValue?.length > 2) {
      setListsByNameIsLoading(true)
      let debouncer = setTimeout(() => {
        getListsByName(1)
      }, 1000)
      return () => {
        clearTimeout(debouncer)
      }
    }
  }, [inputSearchListsValue])

  const handleSubmit = (data) => {
    let payload = { list_group: id, list: listToEdit.list, ...data }
    editList(listToEdit.id, payload)
  }

  const handleClose = () => {
    setModalEditListElements(null)
    setListToEdit(null)
  }

  const filterAndGroupByCategory = (items) => {
    const uniqueCategoryCodes = [...new Set(items.map((item) => item.list_category.code))]
    const groupedItemsByCategory = uniqueCategoryCodes.map((categoryCode) => ({
      categoryCode,
      items: items.filter((item) => item.list_category.code === categoryCode),
    }))
    return groupedItemsByCategory
  }

  const selectListCategory = async (value, list) => {
    if (!isChangingStateOfList) {
      setIsChangingStateOfList(true)
      console.log(list, value)
      let payload = {
        category: value,
      }

      try {
        await methodPatch(changeListCategory(list.list), payload)
      } finally {
        getGroupDetails(id).then(() => {
          setIsChangingStateOfList(false)
        })
      }
    }
  }

  const displayListByCategoryCode = (listByCategoryCode, idx) => {
    /*  console.log('listByCategoryCode', listByCategoryCode) */
    return (
      <Col
        className="table-selected-lists"
        style={{
          marginTop: '2rem',
          padding: '1rem',
          marginLeft: '0.5rem',
          border: '2px solid #d4d4d4',
        }}
        lg={{ span: 12}}
      >
        <DragDropContext
          onDragEnd={(result) => {
            const { source, destination } = result
            if (!destination) {
              return
            }
            if (source.index === destination.index) {
              return
            }
            reOrder(listByCategoryCode.items, source.index, destination.index)
          }}
        >
          <h3>
            {t('category')} : {listByCategoryCode.categoryCode}
          </h3>
          <Table striped={false}>
            <thead>
              <tr className="selected-list-table-row">
                {listByCategoryCode?.items?.length > 1 && (
                  <th
                    style={{
                      minWidth: '6%',
                      maxWidth: '6%',
                    }}
                  >
                    {t('order')}
                  </th>
                )}
                <th 
                  className="selected-list-row-name"
                  style={{
                    minWidth: '44%',
                    maxWidth: '44%',
                  }}
                >
                  {t('name')}
                </th>
                <th
                  style={{
                    minWidth: '10%',
                    maxWidth: '10%',
                  }}
                  className="text-align-center"
                >
                  {t('visible')}
                </th>
                <th
                  style={{
                    minWidth: '20%',
                    maxWidth: '20%'
                  }}
                  className="text-align-center"
                >
                  {t('category')}
                </th> 
                <th
                  style={{
                    minWidth: '20%',
                    maxWidth: '20%',
                  }}
                ></th>
              </tr>
            </thead>
            <Droppable droppableId={'listByCategoryCode' + listByCategoryCode.categoryCode}>
              {(droppableProvided) => (
                <tbody {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                  {listByCategoryCode?.items?.length > 0 &&
                    listByCategoryCode?.items.map((list, index) => (
                      <Draggable key={list.id} draggableId={list.id + 'draggableId'} index={index}>
                        {(draggableProvided) => (
                          <tr
                            className="selected-list-table-row"
                            {...draggableProvided.draggableProps}
                            ref={draggableProvided.innerRef}
                          >
                            {listByCategoryCode?.items?.length > 1 && (
                              <td
                                {...draggableProvided.dragHandleProps}
                                style={{
                                  minWidth: '6%',
                                  maxWidth: '6%',
                                }}
                              >
                                <FaGripVertical size="20" />
                              </td>
                            )}
                            <td 
                              className="selected-list-row-name"
                              style={{
                                minWidth: '44%',
                                maxWidth: '44%',
                              }}
                            >
                              {list.list_name ? list.list_name : t('undefinedName')}
                            </td>
                            <td
                              style={{
                                minWidth: '10%',
                                maxWidth: '10%',
                              }}
                            >
                              <input
                                type="checkbox"
                                style={{ cursor: 'pointer' }}
                                value={list.visible}
                                checked={list.visible}
                                onClick={() => changeStateVisibilityOfList(list)}
                                onChange={() => changeStateVisibilityOfList(list)}
                              />
                            </td>
                            <td
                              style={{
                                minWidth: '20%',
                                maxWidth: '20%',
                              }}
                            >
                              <Select
                                key={list.id}
                                options={categoriesHomePages}
                                defaultValue={list.list_category.code}
                                value={list.list_category.code}
                                handleSelect={(value) => {
                                  selectListCategory(value, list)
                                }}
                              />
                            </td>
                            <td style={{minWidth: '15%',maxWidth: '15%'}}></td>
                            <td
                              style={{
                                minWidth: '5%',
                                maxWidth: '5%',
                                cursor: 'pointer',
                              }}
                              className="d-flex justify-content-center actions-row-buttons"
                            >
                              <span onClick={() => deleteSelectedList(list.id)} className="action-btn-delete">
                                <RiDeleteBinLine />
                              </span>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                  {droppableProvided.placeholder}
                </tbody>
              )}
            </Droppable>
          </Table>
        </DragDropContext>
      </Col>
    )
  }

  useEffect(() => {
    modalOpen === false && handleClose()
  }, [modalOpen])

  useEffect(() => {
    if (selectedLists.length) {
      let filteredLists = filterAndGroupByCategory(selectedLists)
      setSortedListsByCategoryCode(filteredLists)
    } else {
      setSortedListsByCategoryCode(null)
    }
  }, [selectedLists])

  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <ButtonGoBack />
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => navigate('/administrator/group-lists')}>{t('groupsOfLists')}</Breadcrumb.Item>
        <Breadcrumb.Item active>
          {id === 'new' ? t('createNewGroup') : groupDetails?.name ? `${groupDetails.name}` : `#${id}`}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col>
          {isStatus === 404 && <p>{t('errorNoData')}</p>}
          {isStatus === 500 && <p>{t('errorServer')}</p>}
          {!groupDetails && !loading && id !== 'new' && (
            <p className="d-flex justify-content-center m-3">{t('noInfo')}</p>
          )}
          {isChangingStateOfList ? (
            <Loading />
          ) : (
            <>
              <Row className=" w-100 d-flex">
                <Col lg={12}>
                  <h3>{t('name')}</h3>
                </Col>
                <Col lg={12}>
                  <InputCustom
                    placeholder={t('nameOfGroup')}
                    className="border border-gray "
                    value={inputNameValue}
                    onChange={(e) => {
                      setInputNameValue(e.target.value)
                    }}
                  />
                </Col>
              </Row>
              {id !== 'new' && (
                <>
                  <Row className=" w-100 d-flex">
                    <Col lg={12}>
                      <h3>{t('searchLists')}</h3>
                    </Col>
                    <Col lg={12}>
                      <InputCustom
                        placeholder={t('searchLists')}
                        className="border border-gray "
                        value={inputSearchListsValue}
                        onChange={(e) => {
                          setInputSearchListsValue(e.target.value)
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className=" w-100 d-flex">
                    <Col lg={12}>
                      <h3>{t('foundLists')}</h3>
                    </Col>
                    {listsByNameIsLoading ? (
                      <Col lg={12} className="box-message-plug">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">{t('loading')}</span>
                        </Spinner>
                      </Col>
                    ) : listsByName.length ? (
                      <Col className="table-selected-lists" lg={12}>
                        <Table striped responsive>
                          <thead>
                            <tr>
                              <th>{t('listName')}</th>
                              <th>{t('home')}</th>
                              <th>{t('provider')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listsByName.map((list, index) => (
                              <tr key={index}>
                                <td>{list.name ? list.name : t('undefinedName')}</td>
                                <td>{list.category ? list.category : '---'}</td>
                                <td>{list.provider ? list.provider : '---'}</td>
                                <td className="d-flex justify-content-center actions-row-buttons">
                                  <span
                                    onClick={() => !isChangingStateOfList && selectNewList(list)}
                                    className="action-btn-add"
                                  >
                                    <RiAddFill />
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    ) : (
                      <Col lg={12} className="box-message-plug">
                        <span> {t('noResults')}</span>
                      </Col>
                    )}
                  </Row>

                  <Row className=" w-100 d-flex">
                    <Col lg={12}>
                      <h3>{t('selectedLists')}</h3>
                    </Col>
                    {isChangingStateOfList ? (
                      <Col lg={12} className="box-message-plug">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">{t('loading')}</span>
                        </Spinner>
                      </Col>
                    ) : sortedListsByCategoryCode?.length ? (
                      <Row>
                        {sortedListsByCategoryCode
                          .sort((a, b) => a.categoryCode.localeCompare(b.categoryCode))
                          .map((list, idx) => displayListByCategoryCode(list, idx + 1))}
                      </Row>
                    ) : (
                      <Col className="box-message-plug" lg={12}>
                        <span>{t('noSelectedLists')}</span>
                      </Col>
                    )}
                  </Row>
                </>
              )}
              <ComboButtons
                cancelRoute={'/administrator/group-lists'}
                secondaryText={t('back')}
                primaryText={id === 'new' ? t('create') : t('save')}
                handleClick={id === 'new' ? () => createNewGroup() : () => saveChanges()}
              />
            </>
          )}
        </Col>
      </Row>

      <ModalConstructor
        modalOpen={modalOpen && modalEditListElements ? true : false}
        toggleModal={() => toggleModal()}
        modalElements={modalEditListElements}
        onSubmit={(data) => handleSubmit(data)}
      />
    </Container>
  )
}

export default GroupDetails
