import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
//Hook
import useFetch from '../../../hooks/useFetch'
//Style
import '../../../assets/css/charts.scss'
import '../../../assets/css/group_lists.scss'
//Components
import { Row, Container, Col } from 'react-bootstrap'
import Table from '../../../components/table'
import Select from '../../../components/select'
import InputCustom from '../../../components/input'
import Loading from '../../../components/Loading'
//Api
import {
  getProductsBack,
  updateProduct,
  getProvidersBySite,
  updateProductsMassively,
} from '../../../api/pathsForHook/products'
import { CustomButton } from '../../../components/buttons'
import { showToast } from '../../../helpers'
import ButtonGoBack from '../../../components/buttons/GoBackButton'
//context
import { AdminContext } from '../../../contexts/AdminContext'

const ProductsManagement = () => {
  const { t } = useTranslation(['management', 'groupList', 'common'])

  const [products, setProducts] = useState([])
  const [links, setLinks] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [productName, setProductName] = useState('')
  const [productType, setProductType] = useState()
  const [productProviders, setProductProviders] = useState([])
  const [selectedProvider, setSelectedProvider] = useState('')
  const [selectAll, setSelectAll] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedStatus, setSelectedStatus] = useState('')

  const { adminState, setAdminState } = useContext(AdminContext)
  const { methodGet, methodPatch, methodPost, loading, error } = useFetch()

  useEffect(() => {
    if (Object.keys(adminState?.products).length === 0) {
      getProductsList()
    } else {
      setProducts(adminState.products.results)
      setLinks(adminState.products.links)
      setPage(adminState.products.currentPage)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (Object.keys(adminState?.products).length > 0 && Object.keys(links).length > 0) {
          const timeout = setTimeout(() => {
            getProductsList(1)
            setPage(1)
          }, 500)
          return () => clearTimeout(timeout)
        }
      } catch (error) {
        console.error('Error fetching products:', error)
      }
    }

    fetchData()
  }, [productName, productType])

  useEffect(() => {
    if (productProviders.length <= 0) {
      getProviders()
    }
  }, [])

  const getProviders = async () => {
    const { status, data } = await methodGet(getProvidersBySite())
    if (status === 200 || status === 201) {
      const results = data?.filter((item) => item?.content_type?.includes('product'))
      setProductProviders(results)
      return results
    }
  }
  const getProductsList = async (currentPage) => {
    const payload = {
      'page': currentPage || page,
      'paginated': true,
      'fields': 'id,name,type,active,status_global'
    }

    if (productName !== '') {
      payload.name = productName
    }

    if (productType !== '') {
      payload.content_type = productType
    }
    if (selectedProvider !== 'filterBy') {
      payload.provider_pk = selectedProvider
    }

    const { status, data } = await methodGet(getProductsBack(), payload)

    if (status === 200 || status === 201) {
      const currentPageNumber = currentPage || page
      setProducts(data.results)
      data.currentPage = currentPageNumber

      setAdminState({
        ...adminState,
        products: { ...data, currentPage: currentPageNumber },
      })

      setTotalPages(data.total_pages)
      setLinks(data.links)
    }
  }

  const getTypeOptions = () => {
    return [
      { name: t('forms.filterByType', { ns: 'forms' }), value: 'filterBy' },
      { name: t('audiovisual'), value: 'audiovisual' },
      { name: t('music'), value: 'music' },
      { name: t('game'), value: 'game' },
    ]
  }

  const handlePageChange = (e) => {
    setPage(e)
    getProductsList(e)
  }

  const updateProducts = async (filterType, statusChange) => {
    let payload = { ids: selectedItems }
    if (filterType === 'activate') {
      payload = { active: true }
    } else if (filterType === 'status_global') {
      payload = { status_global: statusChange }
      setSelectedStatus(statusChange)
    }
    const { status, data } = await methodPost(updateProductsMassively(), payload)
    if (status === 200 || status === 201) {
      setSelectedItems([])
      setSelectAll(false)
      getProductsList()
      showToast('success', t('success', { ns: 'common' }))
    } else {
      showToast('error', t('error', { ns: 'common' }))
    }
  }

  const changeProduct = async (item, event, type) => {
    let payload = { name: item.name }

    if (type === 'status') {
      payload['status_global'] = event
    } else {
      payload['active'] = !item.active
    }

    const { status, data } = await methodPatch(updateProduct(item.id), payload)

    if (status === 200 || status === 201) {
      const newProducts = products.map((singleProduct) => {
        if (singleProduct.id === item.id) {
          return data
        } else {
          return singleProduct
        }
      })

      setProducts(newProducts)
      setAdminState({
        ...adminState,
        products: {
          results: newProducts,
          links: links,
          currentPage: page,
        },
      })
      showToast('success', t('success', { ns: 'common' }))
    } else {
      showToast('error', t('error', { ns: 'common' }))
    }
  }

  const getStatusOptions = () => {
    return [
      { name: t('comingSoon'), value: '2' },
      { name: t('available'), value: '1' },
    ]
  }
  const selectAllItems = () => {
    const allItemsSelected = selectedItems.length === products.length
    if (allItemsSelected) {
      setSelectedItems([])
    } else {
      const allItemIds = products.map((item) => item.id)
      setSelectedItems(allItemIds)
    }
    setSelectAll(!selectAll)
  }

  const toggleItemSelection = (item) => {
    const isSelected = selectedItems.includes(item.id)
    if (isSelected) {
      setSelectedItems(selectedItems.filter((id) => id !== item.id))
    } else {
      setSelectedItems([...selectedItems, item.id])
    }
  }

  const resetFilters = () => {
    setProductName('')
    setProductType('')
    setSelectedProvider('filterBy')
    setSelectAll(false)
    selectAllItems([])
  }

  useEffect(() => {
    getProductsList(1)
  }, [selectedProvider])

  const renderTable = () => (
    <>
      {products?.length ? (
        <Table
          header={t('headerTableCollectionsManagement', {
            returnObjects: true,
          })}
          page={page}
          changePage={handlePageChange}
          next={links.next ? true : false}
          previous={links.previous ? true : false}
          totalPages={totalPages}
        >
          {products?.map((item, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  style={{ cursor: 'pointer' }}
                  onChange={() => toggleItemSelection(item)}
                  checked={selectedItems.includes(item.id)}
                />
              </td>
              <td>{item.name}</td>
              <td>{item.type}</td>
              <td>
                <input
                  type="checkbox"
                  style={{ cursor: 'pointer' }}
                  checked={item.active}
                  onChange={() => changeProduct(item)}
                />
              </td>
              <td>
                <Select
                  key="select-status"
                  options={getStatusOptions()}
                  handleSelect={(e) => changeProduct(item, e, 'status')}
                  defaultValue={item.status_global}
                />
              </td>
            </tr>
          ))}
        </Table>
      ) : null}
    </>
  )

  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <ButtonGoBack />
      <Row className="mt-5">
        <h2>{t('productManagement')}</h2>
      </Row>
      <Row className="my-2 w-100 d-flex justify-content-between">
        <Col lg={5} md={8} sm={8} xs={12}>
          <InputCustom
            placeholder={t('management:searchByTitle')}
            className="border border-gray"
            onChange={(e) => setProductName(e.target.value)}
            value={productName}
          />
        </Col>
        <Col>
          <Select key="select-type" options={getTypeOptions()} handleSelect={setProductType} value={productType} />
        </Col>
        <Col>
          <Select
            key="select-provider"
            value={selectedProvider}
            options={productProviders}
            defaultOption={{ name: t('forms.filterByProvider', { ns: 'forms' }), value: 'filterBy' }}
            handleSelect={setSelectedProvider}
          />
        </Col>
        <Col>
          <CustomButton onClick={() => selectAllItems()}>
            {selectAllItems.length > 0 ? t('unSelectAll') : t('selectAll')}
          </CustomButton>
        </Col>
        <Col lg={3} md={2} xs={12} className="text-md-end">
          <CustomButton className="online-cprimary mt-0 me-2 btn btn-outline-sucess " onClick={() => resetFilters()}>
            {t('reset', { ns: 'common' })}
          </CustomButton>
        </Col>
      </Row>
      {selectAll && (
        <Row className="my-2 w-100 d-flex justify-content-between">
          <Col>
            <CustomButton onClick={() => updateProducts('activate')}>{t('activate')}</CustomButton>
          </Col>
          <Col>
            <Select
              key="select-status"
              options={getStatusOptions()}
              defaultOption={{ name: 'Cambia el estado', value: 'default' }}
              disabled={true}
              handleSelect={(selectedOption) => {
                updateProducts('status_global', selectedOption)
              }}
              value={selectedStatus}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          {/*                     {isStatus === 500 && (
            <div className="box-message-plug">
              <p>{t('serverError')}</p>
            </div>
          )} */}
          {error ? (
            <div className="box-message-plug">
              <p>{t('errorDataNotFound', { ns: 'groupList' })}</p>
            </div>
          ) : products.length === 0 && !loading ? (
            <Col md={12} className="box-message-plug">
              <p className="d-flex justify-content-center m-3">{t('noResults', { ns: 'common' })}</p>
            </Col>
          ) : loading && products.length === 0 ? (
            <Loading />
          ) : (
            renderTable()
          )}
        </Col>
      </Row>
    </Container>
  )
}
export default ProductsManagement
