import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
// apis
import { getProductsBack, getProvidersBySite } from '../../../api/pathsForHook/products'
import { getFilteredCollectionsListBack } from '../../../api/pathsForHook/collections'
import { createSlider, addSliderToGroupSlider } from '../../../api/pathsForHook/sliders'
// components
import { Container, Row, Col, Form } from 'react-bootstrap'
import Select from '../../../components/select'
import ButtonGoBack from '../../../components/buttons/GoBackButton'
import Button from '../../../components/buttons/CustomButton'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
//translations
import { useTranslation } from 'react-i18next'
//CSS
import '../../../assets/css/group_lists.scss'
//icons
import { FaCheckCircle } from 'react-icons/fa'
import { RiDeleteBin5Fill } from 'react-icons/ri'
// helpers
import { showToast, categoriesHomePages } from '../../../helpers'
// own hooks
import useFetch from '../../../hooks/useFetch'
// routing
import { useParams, useNavigate } from 'react-router-dom'

const CreateSlider = () => {
  const { t } = useTranslation('groupList')

  const [isLoading, setIsLoading] = useState(false)
  const [products, setProducts] = useState([])
  const [category, setCategory] = useState('')
  const [selectedProduct, setSelectedProduct] = useState('')
  const [selectedProductCovers, setSelectedProductCovers] = useState([])
  const [selectedCover, setSelectedCover] = useState('')
  const [selectedImage, setSelectedImage] = useState('')
  const [selectedProvider, setSelectedProvider] = useState('')
  const [typeSlider, setTypeSlider] = useState(1)
  const [status, setStatus] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [providerBySite, setProviders] = useState([])

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm()

  let { id } = useParams()
  let navigate = useNavigate()

  const { methodGet, methodPost, methodPostForm, error } = useFetch()

  const filterBy = () => true

  const typeList = [
    { id: '1', name: 'PRODUCT' },
    { id: '5', name: 'COLLECTION' },
  ]

  const typeProduct = {
    serie: 5,
    all: 1,
    none: 0,
  }

  useEffect(() => {
    const getProviders = async () => {
      const response = await methodGet(getProvidersBySite())
      if (response) setProviders(response.data)
      else {
        console.log('fallo al cargar los proveedores')
      }
    }
    getProviders()
  }, [])

  const checkTypeSlider = (type) => {
    if (type == typeProduct.all) {
      // Por Producto
      if (typeSlider !== typeProduct.all) {
        setSelectedProduct([])
      }
      setTypeSlider(typeProduct.all)
    } else if (type == typeProduct.serie) {
      // Por Colección
      if (typeSlider !== typeProduct.serie) {
        setSelectedProduct([])
      }
      setTypeSlider(typeProduct.serie)
    } else {
      setTypeSlider(typeProduct.none)
    }
  }

  const imageClick = (id, position) => {
    if (id === selectedCover) {
      setSelectedCover('')
    } else {
      setSelectedCover(id)
    }
  }

  const imgFileChange = (event) => {
    if (event.target.files[0]) {
      setSelectedImage(event.target.files[0])

      setSelectedCover('')
    }
  }

  const deleteImage = (event) => {
    if (selectedImage) {
      setSelectedImage('')
    }
  }

  const handleSearch = async (query) => {
    setIsLoading(true)
    const querySearch = { name: query, skip_chapters: true, fields: 'id,slug,name,covers', page_size: 50}
    if (selectedProvider && selectedProvider !== '') {
      querySearch['provider_pk'] = selectedProvider
    }
    if (typeSlider === 1) {
      const { data } = await methodGet(getProductsBack(1), querySearch)
      setProducts(data.results)
    } else if (typeSlider === 5) {
      const { data } = await methodGet(getFilteredCollectionsListBack(1), querySearch)
      setProducts(data.results)
    } else {
      const productData = await methodGet(getProductsBack(1), querySearch)
      const seriesData = await methodGet(getFilteredCollectionsListBack(1), querySearch)
      const array = productData?.data?.results.concat(seriesData?.data?.results)
      setProducts(array)
    }
    setSelectedCover('')
    setSelectedImage('')
    setIsLoading(false)
  }

  const handleSelectedProduct = async (product) => {
    setSelectedProduct(product)
    setSelectedProductCovers(product[0]?.covers)
  }

  const onSubmit = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true)
      let form = new FormData()
      let coverFile = selectedImage
      if (selectedProduct) {
        let product = selectedProduct[0]
        form.append('product_id', product?.id)
        form.append('active', status)
        form.append('order', 1)
        form.append('category', category)
        form.append('type_slider', typeSlider)
        selectedProvider && form.append('slider_provider', selectedProvider)
        coverFile && form.append('cover_file', coverFile)
        selectedCover && form.append('cover_select', selectedCover)
        coverFile && selectedCover && showToast('error', t('modalSlider.chooseOrUpload'))
        if (!selectedProductCovers?.length > 0 && coverFile === undefined && !selectedCover) {
          showToast('error', t('modalSlider.noSlider'))
        } else {
          generateAndAssignSlider(form)
        }
      } else if (!selectedCover || selectedCover === '') {
        //  setErrorCover(true)
        setIsSubmitting(false)
      } else {
        showToast('warn', t('modalSlider.noProduct'))
        setIsSubmitting(false)
      }
    }
    setIsSubmitting(false)
  }

  const generateAndAssignSlider = async (form) => {
    const { data } = await methodPostForm(createSlider(), form)
    data ? await methodPost(addSliderToGroupSlider(id, data.id)) : showToast('error', error)
    data && navigate(`/administrator/slider-group-details/${id}`)
  }

  const checkDisableSaveSliderButton = () => {
    if ((!selectedCover && !selectedImage) || !category || !selectedProduct) {
      return isSubmitting ?? true
    } else {
      return !isSubmitting ??false
    }
  }

  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <ButtonGoBack />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h3>{t('createSlider.createSlider')}</h3>
        <p className="txt-choose">{t('createSlider.sliderType')}</p>
        <Row form>
          <Col md={12}>
            <Select
              key="select-slider-type"
              options={typeList}
              handleSelect={(value) => {
                setTypeSlider(value)
                checkTypeSlider(value)
              }}
              value={typeSlider}
            />
            <p className="text-danger">{errors.typeList && t('modalSlider.typeRequired')}</p>
          </Col>
        </Row>
        <br />
        <p className="txt-choose">{t('createSlider.selectCategory')}</p>
        <Row form>
          <Col md={12}>
            <Select
              key="select-category"
              options={categoriesHomePages}
              handleSelect={(value) => {
                setCategory(value)
              }}
              defaultValue={category}
              required
            />
            <p className="text-danger">{errors.category && t('modalList.categoryRequired')}</p>
          </Col>
        </Row>
        <br />
        {providerBySite && (
          <>
            {' '}
            <p className="txt-choose">{t('createSlider.selectProvider')}</p>
            <Row form>
              <Col md={12}>
                <Select
                  key="select-provider"
                  options={providerBySite}
                  defaultOption={{ value: 0, name: t('createSlider.selectProvider') }}
                  handleSelect={(value) => {
                    if (value === 0) {
                      setSelectedProvider('')
                    } else {
                      setSelectedProvider(value)
                    }
                  }}
                  defaultValue={selectedProvider}
                />
                <p className="text-danger">{errors.provider && t('modalList.providerRequired')}</p>
              </Col>
            </Row>
            <br />{' '}
          </>
        )}
        <p className="txt-choose">{t('createSlider.selectProducts')}</p>
        <Row form>
          <Col md={12}>
            <AsyncTypeahead
              filterBy={filterBy}
              id="id_search_products"
              isLoading={isLoading}
              labelKey={(option) => `${option.name}`}
              minLength={3}
              onSearch={handleSearch}
              onChange={handleSelectedProduct}
              selected={selectedProduct}
              options={products}
              placeholder={t('search', { ns: 'common' })}
            />
          </Col>
        </Row>

        <br />
        <Row form>
          <Col md={12}>
            <span className="txt-choose">{t('active')}</span>

            <input
              name="active"
              type="checkbox"
              //checked={status}
              defaultChecked={status ? status : true}
              // innerRef={register}
              onChange={() => setStatus(!status)}
            />
          </Col>
        </Row>
        {selectedProductCovers && selectedProductCovers.length ? (
          <>
            <p className="txt-choose">{t('createSlider.selectImage')}</p>

            <Row className={'row_gallery' + (Boolean(selectedImage) ? ' disable_div' : '')} form>
              <Col md={12}>
                <Row form>
                  {selectedProductCovers.length > 0 &&
                    selectedProductCovers.map((item, index) => (
                      <Col key={index} md={4} className="gallery-col">
                        <div className="gallery-container">
                          <div
                            onClick={() => imageClick(item.id, index)}
                            className={'gallery-item' + (item.id === selectedCover ? ' selected' : '')}
                          >
                            <img className="gallery-image" src={item.cover} alt="avatar" />

                            <i className={item.id === selectedCover ? ' icon_check' : 'icon_hidden'}>
                              {' '}
                              <FaCheckCircle />{' '}
                            </i>
                          </div>
                        </div>
                      </Col>
                    ))}
                </Row>
              </Col>
            </Row>
          </>
        ) : null}

        <br />

        <p className="txt-choose">{t('createSlider.uploadImage')}</p>

        <Row form>
          <Col md={12}>
            <label className={'file-select'}>
              {t('createSlider.changeImage')}

              <input name="cover_file" type="file" onChange={(event) => imgFileChange(event)} />
            </label>

            {Boolean(selectedImage) ? (
              <div className="cont_img_file">
                <img alt="banner-slider" className="img_file" src={URL.createObjectURL(selectedImage)} />

                <RiDeleteBin5Fill onClick={(event) => deleteImage(event)} className="icon_delete" />
              </div>
            ) : null}
          </Col>
        </Row>

        <Row className="row-buttons-actions">
          <Button
            className=" online-cprimary mt-0 btn btn-outline-sucess  "
            onClick={() => navigate(`/administrator/slider-group-details/${id}`)}
          >
            {t('cancel')}
          </Button>
          {/* {(!selectedCover && !selectedImage) || !category ? (
            <Button onClick={checkFields} className={' mt-0 btn btn-outline-sucess '} disabled={isSubmitting}>
              {t('save')}
            </Button>
          ) : ( */}
          <Button
            type="submit"
            className={' mt-0 btn btn-outline-sucess '}
            disabled={() => checkDisableSaveSliderButton()}
          >
            {t('save')}
          </Button>
          {/* )} */}
        </Row>
      </Form>
    </Container>
  )
}

export default CreateSlider
