// ========== GET METHODS ==========

export const getClientListGroup = (page) => 
page == 1 ? 'products/client-list-group/' : `products/client-list-group/?page=${page}`

export const getGroupDetailsById = (id) => `products/list-group/${id}/`

export const getListGroupLists = () => 'products/list-group/'

export const getProducts = (page) =>
  page === 1 ? 'products/products-administrator/' : `products/products-administrator/?page=${page}`

export const getProductsBack = (page) =>
  page == 1 ? 'products/products-back/' : `products/products-back/?page=${page}`


export const getVisibleLists = (id) => `products/visible-list-group/${id}/`

export const getListById = (id) => `products/lists/${id}/`

export const getListElementsById = (id) => `products/lists/${id}/elements-by-id/`

export const getAllListElements = (id) => `products/lists/${id}/all-list-elements/`

export const getAllLists = (page) => (page === 1 ? 'products/lists/' : `products/lists/?page=${page}`)

export const getExportProductMarc21 = () => 'reports/import-marc-21'

export const getProvidersBySite = () => '/products/providers/by-site/'


// ========== POST METHODS ==========

export const saveAutomaticList = (id) => `products/lists/${id}/assign-all-clients/`

export const createGroup = () => '/products/list-group/'

export const addListToGroupList = () => 'products/visible-list-group/'

export const assignClientListGroup = () => 'products/client-list-group/'

export const activateAllItems =  () => 'enable-products-and-collections/'

// ========== DELETE METHODS ==========

export const deleteGroupListsById = (id) => `/products/list-group/${id}/`

export const deleteClientListGroup = (id) => `products/client-list-group/${id}/`

export const deleteListFromGroupList = (id) => `products/visible-list-group/${id}/`

export const editOrDeleteList = (id) => `products/lists/${id}/`

// ========== PUT METHODS ==========

export const editListFromGroupList = (id) => `products/visible-list-group/${id}/`

export const changeClientListGroup = (id) => `products/client-list-group/${id}/`

export const updateProductsMassively = () => `products/products-administrator/massive-update/`

export const updateCollectionsMassively = () => `products/collections-administrator/massive-update/`


// ========== PATCH METHODS ==========

export const changeOnlyGroup = (id) => `products/client-list-group/${id}/`

export const updateGroup = (id) => `/products/list-group/${id}/`

export const updateProduct = (id) => `/products/products-administrator/${id}/`

export const changeListCategory = (id) => `products/lists/${id}/change-category/`
